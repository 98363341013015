import { onBeforeUnmount, Ref } from "vue";

export interface ObserverOptions {
  iframeRef: Ref<HTMLIFrameElement | null>;
  observedElementSelector: string;
}

export function useIFrameContentResizeObserver({
  iframeRef,
  observedElementSelector,
}: ObserverOptions) {
  const resizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    if (entries.length < 1 || iframeRef.value === null) return;
    const resizedElement = entries[0].target;
    iframeRef.value.style.height = `${resizedElement.clientHeight}px`;
  };
  const resizeObserver = new ResizeObserver(
    (entries: ResizeObserverEntry[]) => {
      // We defer action of adjusting iframe size as it used to throw
      // 'ResizerObserver loop limit exceeded' and this issue is well described
      // here https://github.com/maslianok/react-resize-detector/issues/45#issuecomment-858810843
      setInterval(() => resizeObserverCallback(entries), 0);
    }
  );

  const attachObserver = () => {
    resizeObserver.disconnect();

    const contentDocument = iframeRef.value?.contentDocument;
    const observedElement = contentDocument?.querySelector(
      observedElementSelector
    );
    if (!observedElement) return;

    resizeObserver.observe(observedElement);
  };

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });

  return {
    attachObserver,
  };
}

export type UseIFrameContentResizeObserver =
  typeof useIFrameContentResizeObserver;
