
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onBeforeUnmount, toRefs } from "vue";
import { PropType } from "vue";

import { useIFrameContentResizeObserver } from "@/composables/useIFrameContentResizeObserver";
import { ProductLine } from "@/lib/productLine";

import { TargetDialogIFrameUrlFunction } from "../typedefs";
import { useTargetAddEditDialog } from "./useTargetAddEditDialog";

export default defineComponent({
  props: {
    targetId: {
      required: false,
      type: Number,
      default: undefined,
    },
    productLine: {
      required: true,
      type: Object as PropType<ProductLine>,
    },
    iframeUrl: {
      required: true,
      type: Function as PropType<TargetDialogIFrameUrlFunction>,
    },
  },
  emits: Array.from(useDialogPluginComponent.emits),
  setup(props) {
    const $q = useQuasar();
    return useTargetAddEditDialog({
      ...toRefs(props),
      onBeforeUnmount,
      onNotify: (options) => $q.notify(options),
      useIFrameContentResizeObserver,
      useDialogPluginComponent,
    });
  },
});
