import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Ref } from "vue";

import { ProductLine } from "@/lib/productLine";

import { TargetDialogIFrameUrlFunction } from "../typedefs";
import TargetAddEditDialog from "./TargetAddEditDialog.vue";

interface TargetAddEditDialogProviderOptions {
  productLine: Ref<ProductLine>;
  iframeUrl: Ref<TargetDialogIFrameUrlFunction>;
}

// NOTE: This method is used to render dialog.
// (1) Normally we call such method use<name of dialog component>.
// (2) But for TargetAddEditDialog.vue we also wanted to use
// use<name of dialog component> pattern to extract logic from component.
// TODO(PNS-2182): As result of conflict described above, we need to propose a
//                 different convention for naming methods to render dialog.
export function useTargetAddEditDialogProvider({
  productLine,
  iframeUrl,
}: TargetAddEditDialogProviderOptions) {
  const $q = useQuasar();

  const openDialog = (targetId?: number) =>
    $q.dialog({
      component: TargetAddEditDialog,
      componentProps: {
        targetId,
        productLine: productLine.value,
        iframeUrl: iframeUrl.value,
      },
    });

  return {
    openDialog,
  };
}
