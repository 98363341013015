import type { useDialogPluginComponent as useDialogPluginComponentType } from "quasar";
import { computed, Ref, ref } from "vue";

import { UseIFrameContentResizeObserver } from "@/composables/useIFrameContentResizeObserver";
import { convertIFrameMessageData, MessageType } from "@/lib/iframe";
import { ProductLine } from "@/lib/productLine";
import { ShallowReadonlyRef } from "@/lib/typing";

import { TargetDialogIFrameUrlFunction } from "../typedefs";

interface UseTargetAddEditDialogOptions {
  iframeUrl: ShallowReadonlyRef<TargetDialogIFrameUrlFunction>;
  productLine: ShallowReadonlyRef<ProductLine>;
  targetId: ShallowReadonlyRef<number | undefined>;
  onBeforeUnmount: (callback: () => void) => void;
  useDialogPluginComponent: useDialogPluginComponentType;
  useIFrameContentResizeObserver: UseIFrameContentResizeObserver;
  onNotify: (options: { message: string; type: "positive" }) => void;
}

export function useTargetAddEditDialog({
  iframeUrl,
  productLine,
  targetId,
  onBeforeUnmount,
  useDialogPluginComponent,
  useIFrameContentResizeObserver,
  onNotify,
}: UseTargetAddEditDialogOptions) {
  const iframeRef: Ref<HTMLIFrameElement | null> = ref(null);

  const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
  const { attachObserver } = useIFrameContentResizeObserver({
    iframeRef,
    observedElementSelector: ".popup",
  });

  const onFormStatusChange = (
    status: "succeeded" | "failed",
    message: string
  ) => {
    if (status !== "succeeded") return;

    const matchedTargetId = message.match(/\d+/)?.[0];
    const targetId = Number(matchedTargetId);
    if (!Number.isInteger(targetId)) {
      throw new Error("Failed to read add/edit target id.");
    }

    onDialogOK({ targetId });
    onNotify({ type: "positive", message });
  };

  const eventHandler = (event: MessageEvent<unknown>) => {
    const data = convertIFrameMessageData(event.data);
    switch (data?.type) {
      case MessageType.POPUP_CREATED: {
        attachObserver();
        break;
      }
      case MessageType.POPUP_CANCEL_CLICKED: {
        onDialogHide();
        break;
      }
      case MessageType.FORM_STATUS: {
        const { status, message } = data.payload;
        onFormStatusChange(status, message);
        break;
      }
    }
  };

  const iframeSrc = computed(() =>
    iframeUrl.value(productLine.value, targetId.value)
  );

  window.addEventListener("message", eventHandler);

  onBeforeUnmount(() => {
    window.removeEventListener("message", eventHandler);
  });

  return {
    dialogRef,
    iframeRef,
    iframeSrc,
    onDialogOK,
    onDialogHide,
  };
}
